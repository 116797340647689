import axios from "axios";

const loadInboxStatus = async () => {
  const res = await axios.get("/api/inbox/messages/status");
  return res.data;
};

const loadMessages = async (conversationId) => {
  if (!conversationId) return;
  try {
    const res = await axios.get(
      `/api/inbox/messages?conversation_id=${conversationId}`,
    );
    return res.data;
  } catch (err) {
    return false;
  }
};

const loadMoreMessages = async (conversationId, offset = 0) => {
  if (!conversationId) return;
  const res = await axios.get(
    `/api/inbox/messages?conversation_id=${conversationId}&offset=${offset}`,
  );
  return res.data;
};

const loadMessage = async (messageId) => {
  const res = await axios
    .get(`/api/inbox/messages/${messageId}`, headers())
    .catch((err) => console.log(err));
  if (!res) return;
  return res.data;
};

const updateMessage = async (messageId, payload) => {
  const res = await axios.patch(
    `/api/inbox/messages/${messageId}`,
    payload,
    headers(),
  );
  return res.data;
};

const destroyMessage = async (messageId) => {
  return await axios.delete(`/api/inbox/messages/${messageId}`, headers());
};

const retryMessage = async (messageId) => {
  const res = axios.post(
    `/api/inbox/messages/${messageId}/retry`,
    {},
    headers(),
  );
  return res.data;
};

const triggerMessageAutomation = async (messageId) => {
  const res = axios.post(
    `/api/inbox/messages/${messageId}/trigger_automation`,
    {},
    headers(),
  );
  return res.data;
};

export {
  loadInboxStatus,
  loadMessages,
  loadMoreMessages,
  loadMessage,
  updateMessage,
  destroyMessage,
  retryMessage,
  triggerMessageAutomation,
};
