import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../contexts/user-context";
import {
  ArrowPathIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import Button from "../../elements/Button";

export default function InstagramMissingPermissions() {
  const { t } = useTranslation();

  const { organization, missingPermissions } = useContext(UserContext);

  if (!organization.instagram_connected) return null;

  if (!missingPermissions?.length) return null;

  return (
    <div className="p-3 rounded-lg bg-yellow-200 space-y-2">
      <div className="flex space-x-2 items-center">
        <ExclamationTriangleIcon className="w-5 h-5 text-yellow-800" />
        <div className="font-medium text-sm">
          {t("profile.external_accounts.instagram.missing_permissions.title")}
        </div>
      </div>
      <div className="text-sm">
        {t(
          "profile.external_accounts.instagram.missing_permissions.description",
        )}
      </div>
      <div className="space-y-1">
        {missingPermissions.map((permission) => (
          <div
            key={permission}
            className="text-2sm font-medium p-2 bg-white rounded"
          >
            {t(
              "profile.external_accounts.instagram.missing_permissions." +
                permission,
            )}
          </div>
        ))}
      </div>
      <Button
        label={t(
          "profile.external_accounts.instagram.missing_permissions.reconnect",
        )}
        href={`/users/auth/instagram`}
        icon={ArrowPathIcon}
        className="w-full"
        size="small"
      />
    </div>
  );
}
