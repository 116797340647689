import React, { useContext, useEffect, useState } from "react";
import { ContactsContext } from "../../contexts/contacts-context";
import ContactHeader from "./ContactHeader";
import EditContactFolders from "../inbox/folders/EditContactFolders";
import Input from "../elements/Input";
import { useTranslation } from "react-i18next";
import Button from "../elements/Button";
import { delay } from "lodash";
import InputSelect from "../elements/InputSelect";

export default function EditContact(props) {
  const { t } = useTranslation();

  const { contactId, onClose } = props;

  const {
    languages,
    loadLanguages,
    contact,
    setContact,
    loadContact,
    updateContact,
    contactProperties,
  } = useContext(ContactsContext);

  const [saving, setSaving] = useState(false);
  const [propertyValues, setPropertyValues] = useState([]);

  useEffect(async () => {
    const res = await loadContact(contactId);
    setContact(res);
  }, [contactId]);

  const handleChange = (key) => (value) => {
    setContact((prev) => ({ ...prev, [key]: value }));
  };

  // Properties

  useEffect(() => {
    setPropertyValues(
      contactProperties.map((prop) => {
        const propertyValue = contact?.properties?.find(
          (p) => p.contact_property_id === prop.id,
        );
        return {
          contact_property_id: prop.id,
          value: propertyValue?.value,
          id: propertyValue?.id,
        };
      }),
    );
  }, [contact?.properties, contactProperties]);

  const handleChangePropertyValue = (propertyId) => (value) => {
    setPropertyValues((prev) =>
      prev.map((propValue) =>
        propValue.contact_property_id === propertyId
          ? { ...propValue, value }
          : propValue,
      ),
    );
  };

  // Save

  const handleSave = async () => {
    setSaving(true);
    const payload = {
      ...contact,
      contact_property_values_attributes: propertyValues,
    };
    const res = await updateContact(payload);
    setContact(res);
    delay(() => setSaving(false), 300);
  };

  const languageOptions = [
    { value: null, label: t("contacts.no_language") },
    ...languages.map((language) => ({
      value: language,
      label: t("languages." + language),
    })),
  ];

  useEffect(loadLanguages, []);

  return (
    <div className="w-[412px]">
      <div className="p-3 pb-6 space-y-3">
        <ContactHeader contact={contact} loading={!contact} />
        <EditContactFolders contact={contact} loading={!contact} />
        <Input
          label={t("inbox.details.email")}
          placeholder={t("inbox.details.email_placeholder")}
          size="small"
          value={contact?.email}
          onChange={handleChange("email")}
        />
        <Input
          label={t("inbox.details.phone")}
          placeholder={t("inbox.details.phone_placeholder")}
          size="small"
          value={contact?.phone}
          onChange={handleChange("phone")}
        />
        <InputSelect
          label={t("contacts.columns.language")}
          value={contact?.language}
          onChange={handleChange("language")}
          options={languageOptions}
        />
        {contactProperties.map((prop) => (
          <Input
            key={prop.id}
            label={prop.title}
            value={
              propertyValues.find((p) => p.contact_property_id == prop.id)
                ?.value
            }
            onChange={handleChangePropertyValue(prop.id)}
            type={prop.property_type}
          />
        ))}
      </div>
      <div className="border-t p-3 flex-shrink-0 flex justify-between">
        <Button label={t("shared.cancel")} onClick={onClose} />
        <Button
          label={t("contacts.save")}
          onClick={handleSave}
          style="primary"
          loading={saving}
        />
      </div>
    </div>
  );
}
