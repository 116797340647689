import React, { useContext, useEffect, useRef, useState } from "react";
import { AgentContext } from "~/contexts/agent-context";
import classNames from "~/utils/classNames";
import { useTranslation } from "react-i18next";
import Textarea from "react-expanding-textarea";
import Loader from "~/components/utils/Loader";
import { delay } from "lodash";
import LoveReaction from "../messages/LoveReaction";

export default function AgentChat(props) {
  const { className = "" } = props;

  const { t } = useTranslation();

  // when agent is saving, disable the chat (training)
  const { loading: training, agent, askAgent } = useContext(AgentContext);

  const inputRef = useRef(null);

  const [messages, setMessages] = useState([]);

  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const handleTextChange = (event) => {
    setMessage(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    if (message.length <= 0) return;

    setMessages((messages) => [
      { received: false, content: message },
      ...messages,
    ]);

    // send message to agent
    setMessage("");

    setLoading(true);

    const response = await askAgent(message);

    // receive message from agent
    if (response.reply) {
      setMessages((messages) => [
        {
          received: true,
          content: response.reply,
        },
        { ...messages[0], like: response.like },
        ...messages.slice(1),
      ]);
    }

    setLoading(false);

    // focus message input
    inputRef.current.focus();
  };

  useEffect(() => {
    setMessages([]);
  }, [agent]);

  return (
    <div
      className={classNames(
        "flex-grow flex flex-col p-3 space-y-3 overflow-hidden border rounded-lg cursor-pointer focus-within:cursor-default relative",
        className,
        training && "pointer-events-none !cursor-default",
      )}
      onClick={() => inputRef.current.focus()}
    >
      <div className="text-sm font-medium">{t("agent.chat.title")}</div>
      <div className="flex-grow flex flex-col-reverse space-y-2 space-y-reverse overflow-y-auto">
        {messages.map((message, index) => (
          <div
            key={index}
            className={classNames(
              "flex",
              message.received ? "justify-start" : "justify-end",
            )}
          >
            <div
              className={classNames(
                "text-message relative !max-w-52",
                message.received && "received",
                message.like && "mb-2",
              )}
            >
              {message.content}
              {message.like && (
                <LoveReaction className="absolute left-2 -bottom-3.5" />
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="flex items-start rounded-2.5xl bg-white border border-neutral-300 focus-within:ring-1">
        <Textarea
          className="text-sm border-none bg-transparent focus:ring-0 outline-none py-2 pl-2.5 flex-grow [max-height:120px] resize-none leading-tight"
          placeholder={t("inbox.conversation.compose")}
          value={message}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
          disabled={training || loading}
          ref={inputRef}
        />
        {loading ? (
          <div className="p-2.5">
            <Loader width={16} />
          </div>
        ) : null}
        {message.length > 0 ? (
          <button
            className="py-1.5 pl-2 pr-2.5 flex-shrink-0 font-medium text-sm text-primary"
            onClick={handleSubmit}
          >
            {t("inbox.conversation.send")}
          </button>
        ) : null}
      </div>
      {training && (
        <div className="absolute inset-0 bg-white/50 flex flex-col items-center justify-center">
          <Loader />
        </div>
      )}
    </div>
  );
}
