import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import PageHeader from "~/components/shared/PageHeader";
import FullScreen from "~/components/utils/FullScreen";

import ShopifyAccount from "./accounts/ShopifyAccount";
import CalendlyAccount from "./accounts/CalendlyAccount";
import { SubscriptionContext } from "../../contexts/subscription-context";

export default function IntegrationsPage(props) {
  const { back } = props;

  const { organization } = useContext(UserContext);
  const { limits } = useContext(SubscriptionContext);

  const { t } = useTranslation();

  if (limits.features?.includes("integrations")) return null;

  return (
    <FullScreen>
      <PageHeader
        title={t("profile.integrations")}
        back={back}
        className="mb-4"
      />
      <div className="p-4 space-y-4">
        {organization.features.ecommerce && <ShopifyAccount />}
        <CalendlyAccount />
      </div>
    </FullScreen>
  );
}
