export default function formatMessageText(text) {
  return (text || "")
    .replace(
      /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
      function (match, space, url) {
        var hyperlink = url;
        if (!hyperlink.match("^https?://")) {
          hyperlink = "http://" + hyperlink;
        }
        return (
          space +
          '<a class="text-primary" href="' +
          hyperlink +
          '" target="_blank">' +
          url +
          "</a>"
        );
      }
    )
    .replace(/\n/gm, "<br />");
}
