import React, { useCallback, useContext, useEffect, useState } from "react";
import axios from "axios";
import { UserContext } from "~/contexts/user-context";
import { AgentContext } from "~/contexts/agent-context";
import headers from "~/utils/headers";
import { UIContext } from "~/contexts/ui-context";

export default function AgentProvider(props) {
  const [loading, setLoading] = useState(true);

  const { showError } = useContext(UIContext);
  const { organization } = useContext(UserContext);

  const [agent, setAgent] = useState(null);

  const loadAgent = useCallback(async () => {
    setLoading(true);
    const res = await axios.get("/api/ai/agent");
    setAgent(res.data);
    setLoading(false);
  }, [setLoading, setAgent]);

  useEffect(loadAgent, [organization.id]);

  const updateAgent = async (payload) => {
    try {
      const res = await axios.patch("/api/ai/agent", payload, headers());
      setAgent(res.data);
      return res.data;
    } catch (error) {
      const errorKey = error.response?.data?.errors?.base?.[0];
      console.log({ errorKey });
      if (errorKey) showError(errorKey);
    }
  };

  const askAgent = async (text) => {
    const res = await axios.post("/api/ai/agent/question", { text }, headers());
    return res.data;
  };

  const value = {
    loading,
    agent,
    loadAgent,
    updateAgent,
    askAgent,
  };

  return (
    <AgentContext.Provider value={value}>
      {props.children}
    </AgentContext.Provider>
  );
}
