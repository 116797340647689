import React, { useContext } from "react";
import { UserContext } from "../../../contexts/user-context";
import { useTranslation } from "react-i18next";
import {
  ExclamationTriangleIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/20/solid";
import Button from "../../elements/Button";

export default function SidebarAuthorizationError() {
  const { t } = useTranslation();

  const { organization } = useContext(UserContext);

  if (organization.facebook_error) {
    return (
      <div className="p-3 rounded-lg bg-warning space-y-2">
        <div className="flex space-x-2 items-center">
          <ExclamationTriangleIcon className="w-5 h-5 text-black" />
          <div className="font-medium text-sm">
            {t("profile.external_accounts.instagram.authorization_error.title")}
          </div>
        </div>
        <div className="text-sm">
          {t(
            "profile.external_accounts.instagram.authorization_error.description",
          )}
        </div>
        <Button
          label={t(
            "profile.external_accounts.instagram.authorization_error.view_issue",
          )}
          to="/inbox"
          icon={WrenchScrewdriverIcon}
          className="w-full block"
          size="small"
        />
      </div>
    );
  }

  return null;
}
