import React, { useContext, useState } from "react";
import instagramIcon from "../../../../assets/images/instagram_icon.png";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import ContactHeader from "../../contacts/ContactHeader";
import Button from "~/components/elements/Button";
import InboxModalError from "../../errors/InboxModalError";
import NoConnection from "../../errors/NoConnection";
import { UIContext } from "~/contexts/ui-context";
import { ArrowPathIcon, TrashIcon } from "@heroicons/react/20/solid";
import Badge from "~/components/elements/Badge";
import InstagramMissingPermissions from "./InstagramMissingPermissions";

export default function InstagramAccount() {
  const { organization } = useContext(UserContext);

  const { t } = useTranslation();

  const { showAlert } = useContext(UIContext);

  const [connectInstagram, setConnectInstagram] = useState(false);

  const handleDisconnect = () => {
    showAlert({
      title: t("profile.external_accounts.instagram.confirm_disconnect"),
      confirm: t("shared.yes"),
      cancel: t("shared.no"),
      confirmClassName: "text-red-500",
      onSubmit: () => {
        window.location.replace(`/users/auth/instagram/disconnect`);
      },
    });
  };

  return (
    <div className="border rounded-2xl rounded-tl-none p-4 space-y-4">
      <div className="flex items-center justify-between">
        <div className="text-md flex">
          <img src={instagramIcon} className="w-5 mr-2" />
          {t("profile.external_accounts.instagram.title")}
        </div>
        {organization.instagram_connected ? (
          <Badge color="green" label={t("shared.connected")} />
        ) : (
          <Badge color="yellow" label={t("shared.not_connected")} />
        )}
      </div>
      <div className="text-2sm">
        {t("profile.external_accounts.instagram.subtitle")}
      </div>
      {organization.instagram_connected ? (
        <div className="p-3 bg-lighter-gray rounded-xl group flex flex-col items-start space-y-3">
          <div className="w-full flex items-center justify-between">
            <ContactHeader contact={organization} />
            <div className="flex space-x-4 group-hover:hidden">
              <div className="flex flex-col items-center">
                <div className="text-md font-medium">
                  {organization.followers_count || "-"}
                </div>
                <div className="text-2xs">
                  {t("profile.external_accounts.instagram.followers")}
                </div>
              </div>
              <div className="flex flex-col items-center">
                <div className="text-md font-medium">
                  {organization.follows_count || "-"}
                </div>
                <div className="text-2xs">
                  {t("profile.external_accounts.instagram.following")}
                </div>
              </div>
            </div>
            <div className="space-x-2 hidden group-hover:flex">
              <Button
                href={`/users/auth/instagram`}
                icon={ArrowPathIcon}
                size="small"
              />
              <Button
                onClick={handleDisconnect}
                icon={TrashIcon}
                size="small"
              />
            </div>
          </div>
          {organization.facebook_user_name && (
            <div className="rounded-md bg-light-gray text-darker-gray px-2 py-1 text-2sm space-x-2">
              {t("profile.external_accounts.instagram.connected_with_account")}{" "}
              <strong>{organization.facebook_user_name}</strong>
            </div>
          )}
        </div>
      ) : (
        <Button
          label={t("profile.external_accounts.instagram.connect")}
          className="w-full"
          onClick={() => setConnectInstagram(true)}
        />
      )}
      <InstagramMissingPermissions />
      {connectInstagram && (
        <InboxModalError onBackgroundClick={() => setConnectInstagram(false)}>
          <NoConnection setHidden={setConnectInstagram} />
        </InboxModalError>
      )}
    </div>
  );
}
