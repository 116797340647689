import React, { useEffect, useRef } from "react";
import { XCircleIcon } from "@heroicons/react/20/solid";
import Loader from "~/components/utils/Loader";
import { useTranslation } from "react-i18next";

export default function InputField(props) {
  const { t } = useTranslation();

  const {
    loading = false,
    label,
    icon,
    placeholder,
    name,
    value = "",
    type = "text",
    onChange,
    onInput,
    onFocus,
    hideClear = false,
    onClear,
    rightGroup,
    required = false,
    showCancel = false,
    onCancel,
    autoFocus = false,
    disabled = false,
    className = "",
    ...inputProps
  } = props;

  const handleClear = () => {
    onChange("");
    onClear && onClear();
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocus) inputRef.current?.focus();
  }, [autoFocus]);

  return (
    <>
      {label ? (
        <label for={name} className="text-md mb-1">
          {label}
        </label>
      ) : null}
      <div className={`flex-grow flex items-center ${className}`}>
        <div className="rounded-lg bg-neutral-100 flex items-center flex-grow">
          {loading ? (
            <div className="w-5 mr-2 flex items-center justify-center flex-shrink-0">
              <Loader stroke="#8D8D8D" width={22} strokeWidth={6} />
            </div>
          ) : icon ? (
            <div className="w-5 mr-2 flex items-center justify-center flex-shrink-0">
              {icon}
            </div>
          ) : null}
          <input
            ref={inputRef}
            type={type}
            name={name}
            id={name}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="none"
            autoFocus={autoFocus}
            spellCheck="false"
            placeholder={
              placeholder + (required ? "" : ` (${t("shared.optional")})`)
            }
            className="w-full border-none bg-transparent focus:outline-none py-2 pr-3 focus:ring-0 text-sm"
            value={value}
            onChange={onChange ? (evt) => onChange(evt.target.value) : null}
            onInput={onInput ? (evt) => onInput(evt.target.value) : null}
            onFocus={onFocus}
            disabled={loading || disabled}
            {...inputProps}
          />
          {value.length ? (
            <button
              className="h-8 flex items-center justify-center px-2 flex-shrink-0"
              onClick={handleClear}
            >
              <XCircleIcon className="w-5 h-5 text-neutral-400" />
            </button>
          ) : null}
          {rightGroup ? (
            <div className="text-sm text-darker-gray pl-2 pr-3 border-l">
              {rightGroup}
            </div>
          ) : null}
        </div>
        {showCancel && (
          <button className="pl-3" onClick={onCancel}>
            {t("shared.cancel")}
          </button>
        )}
      </div>
    </>
  );
}
