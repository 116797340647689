export default function orderActions(actions) {
  // order actions in a tree like structure based on parent_id

  const orderedActions = [];

  // root parent action
  const firstAction = actions.find((action) => !action.parent_id);
  orderedActions.push(firstAction);

  addChildren(firstAction);

  // recursive function to add children
  function addChildren(action) {
    const children = actions.filter((a) => a.parent_id === action.id);
    children.forEach((child) => {
      orderedActions.push(child);
      addChildren(child);
    });
  }

  return orderedActions;
}
