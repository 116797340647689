import React, { useState } from "react";
import Modal from "../shared/Modal";
import Button from "../elements/Button";
import { LockOpenIcon, SparklesIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { delay } from "lodash";
import axios from "axios";
import headers from "../../utils/headers";

export default function AgentRequestAccess() {
  const { t } = useTranslation();

  const [accessRequested, setAccessRequested] = useState(false);

  const requestAccess = () => {
    axios.post("/api/ai/agent/request_access", {}, headers());
    delay(() => setAccessRequested(true), 2000);
  };

  return (
    <Modal className="max-w-sm" preventClose showSidebar>
      <div className="flex flex-col space-y-4 p-3 pb-6 sm:p-4">
        <div className="font-medium text-lg">
          <SparklesIcon className="w-5 inline-block mr-2" />
          {t("agent.beta.title")}
        </div>
        <div className="text-sm">{t("agent.beta.description")}</div>
        {accessRequested ? (
          <div className="text-center text-sm font-medium text-green-500 py-2">
            {t("agent.beta.requested")}
          </div>
        ) : (
          <Button
            label={t("agent.beta.request_access")}
            style="primary"
            className="w-full"
            icon={LockOpenIcon}
            loadingOnClick
            onClick={requestAccess}
          />
        )}
      </div>
    </Modal>
  );
}
