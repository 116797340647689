import React, { useContext, useEffect, useState } from "react";
import { MediaFilesContext } from "~/contexts/media-files-context";
import ImageMessage from "../../messages/ImageMessage";
import VideoMessage from "../../messages/VideoMessage";
import Loader from "~/components/utils/Loader";
import { useTranslation } from "react-i18next";

export default function MediaMessageAction(props) {
  const { t } = useTranslation();

  const { action } = props;

  const { getMedia, createMedia } = useContext(MediaFilesContext);

  const [mediaFile, setMediaFile] = useState(null);

  useEffect(() => {
    if (action.options?.media_file_id) {
      getMedia(action.options?.media_file_id).then((media) => {
        setMediaFile(media);
      });
    }
  }, [action.options?.media_file_id]);

  return mediaFile ? (
    mediaFile.media_type === "image" ? (
      <ImageMessage
        url={mediaFile.media_url}
        dimensions={mediaFile.media_dimensions}
      />
    ) : (
      <VideoMessage
        url={mediaFile.media_url}
        preview_url={mediaFile.media_preview_url}
        className="w-40 h-40 max-h-55"
      />
    )
  ) : (
    <div className="py-3 px-4 bg-white border-2 border-medium-gray text-dark-gray border-dashed rounded-2xl cursor-pointer nodrag opacity-75 hover:opacity-100 transition-opacity flex space-x-2 items-center">
      <Loader width={24} strokeWidth={8} />
      <div className="text-md font-medium text-center">
        {t("automation.scenarios.actions.media_message.loading")}
      </div>
    </div>
  );
}
