import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import axios from "axios";
import { SubscriptionContext } from "~/contexts/subscription-context";
import headers from "~/utils/headers";
import { UIContext } from "../contexts/ui-context";
import { useTranslation } from "react-i18next";

export default function SubscriptionProvider(props) {
  const { organizationId } = props;

  const { t } = useTranslation();

  const { showAlert } = useContext(UIContext);

  const [subscription, setSubscription] = useState({});

  const loadSubscription = async () => {
    const res = await axios.get("/api/subscription");
    setSubscription(res.data);
    return res.data;
  };

  const startFreeTrial = async () => {
    const res = await axios.post("/api/subscription", {}, headers());
    setSubscription(res.data);
    return res.data;
  };

  const limitReached = useMemo(() => {
    return subscription.contacts_activated >= subscription.current_tier_maximum;
  }, [subscription]);

  useEffect(loadSubscription, [organizationId]);

  // set booleans for subscription tiers
  const { isFree, isTrial, isPro } = useMemo(() => {
    const isTrial = subscription.subscription_tier == "trial";
    const isPro = subscription.subscription_tier != "free" && !isTrial;
    const isFree = subscription.subscription_tier == "free";
    return { isFree, isTrial, isPro };
  }, [subscription]);

  const limits = useMemo(() => {
    return subscription.subscription_limits || {};
  }, [subscription]);

  const limitedFeatureAlert = useCallback(() => {
    showAlert({
      title: t("subscription.feature_limited_title"),
      message: t("subscription.feature_limited_description"),
      confirm: t("subscription.upgrade_to_pro"),
      onSubmit: () => {
        window.location.href = "/stripe/checkout_session";
      },
    });
  }, [showAlert]);

  const values = {
    subscription,
    isFree,
    isTrial,
    isPro,
    limits,
    limitedFeatureAlert,
    loadSubscription,
    startFreeTrial,
    limitReached,
  };

  return (
    <SubscriptionContext.Provider value={values}>
      {props.children}
    </SubscriptionContext.Provider>
  );
}
