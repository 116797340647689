import axios from "axios";

const getContacts = async (params) => {
  const response = await axios.get(`/api/contacts/contacts`, { params });
  return response.data;
};

const getContact = async (id) => {
  const response = await axios.get(`/api/contacts/contacts/${id}`);
  return response.data;
};

export { getContacts, getContact };
