import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlowContext } from "~/contexts/flow-context";
import {
  ExclamationTriangleIcon,
  LinkIcon,
  PencilIcon,
  PencilSquareIcon,
} from "@heroicons/react/20/solid";
import CustomNode from "./CustomNode";
import TemplateMessage from "~/components/messages/TemplateMessage";
import Modal from "~/components/shared/Modal";
import { getEventTypes } from "~/api/calendly";
import Input from "~/components/elements/Input";
import Button from "~/components/elements/Button";
import CalendlyIcon from "~/components/shared/icons/CalendlyIcon";
import { UserContext } from "~/contexts/user-context";

export default function CalendlyLinkNode(props) {
  const { t } = useTranslation();

  const { editAction } = useContext(FlowContext);

  const { selected, data } = props;
  const { id, options, editable = false, event_type, plan } = data;

  const { button_label } = options;
  const [editButtonLabel, setEditButtonLabel] = useState(false);

  const [eventTypes, setEventTypes] = useState([]);
  const [eventTypesModal, setEventTypesModal] = useState(false);

  const handlePick = (eventType) => {
    setEventTypesModal(false);
    editAction(id, {
      event_type: eventType,
      options: { ...options, calendly_event_type_id: eventType.id },
    });
  };

  useEffect(() => {
    if (editable) setEventTypesModal(true);
  }, [editable]);

  // Fetch event types when modal is opened
  useEffect(async () => {
    if (eventTypesModal) {
      const res = await getEventTypes();
      setEventTypes(res);
    }
  }, [eventTypesModal]);

  return (
    <CustomNode
      {...props}
      buttons={
        event_type && [
          {
            icon: PencilSquareIcon,
            onClick: () => setEventTypesModal(true),
          },
        ]
      }
      icon={CalendlyIcon}
    >
      <div className={`relative ${selected || editable ? "z-50" : null}`}>
        {event_type ? (
          <TemplateMessage
            className={`ring-1 ring-offset-1 ring-indigo-400 ring-opacity-0 hover:ring-opacity-100 ${
              selected ? "ring-opacity-100" : null
            }`}
            template={{
              title: event_type.name,
              subtitle: event_type.description_plain,
              buttons: [],
            }}
          >
            {editButtonLabel ? (
              <Input
                value={button_label}
                onChange={(value) =>
                  editAction(id, {
                    options: { ...options, button_label: value },
                  })
                }
                placeholder={t(
                  "automation.scenarios.actions.calendly_link.button_placeholder",
                )}
                onBlur={() => setEditButtonLabel(false)}
                changeOnBlur
                autoFocus
              />
            ) : (
              <div className="flex items-center space-x-2">
                <Button
                  className="flex-grow"
                  label={button_label || "Book appointment"}
                  style="soft"
                  href={event_type.scheduling_url}
                />
                <Button
                  size="small"
                  onClick={() => setEditButtonLabel(true)}
                  icon={PencilIcon}
                />
              </div>
            )}
            {plan == "basic" && (
              <>
                <div className="p-2 rounded-lg bg-yellow-200 flex items-start space-x-1 text-yellow-800">
                  <ExclamationTriangleIcon className="w-6 mt-0.5" />
                  <div className="text-2sm">
                    {t("automation.scenarios.actions.calendly_link.free_plan")}
                  </div>
                </div>
              </>
            )}
          </TemplateMessage>
        ) : (
          <div
            className="py-3 px-4 bg-white border-2 border-medium-gray text-dark-gray border-dashed rounded-2xl cursor-pointer nodrag opacity-75 hover:opacity-100 transition-opacity flex space-x-2 items-center"
            onClick={() => setEventTypesModal(true)}
          >
            <LinkIcon className="w-4" />
            <div className="text-md font-medium text-center">
              {t("automation.scenarios.actions.calendly_link.placeholder")}
            </div>
          </div>
        )}
      </div>
      {eventTypesModal && (
        <Modal
          className="max-w-md"
          title={t("automation.scenarios.actions.calendly_link.placeholder")}
          onClose={() => setEventTypesModal(false)}
        >
          <div className="grid grid-cols-1 gap-2 p-3">
            {eventTypes.map((eventType) => (
              <div
                key={eventType.id}
                className="p-3 rounded-lg border hover:bg-neutral-50 transition-colors cursor-pointer"
                onClick={() => handlePick(eventType)}
              >
                <div className="font-medium mb-1">{eventType.name}</div>
                <div className="text-sm text-muted">
                  {eventType.description_plain}
                </div>
                <div className="text-xs mt-2 text-primary">
                  {eventType.scheduling_url}
                </div>
              </div>
            ))}
          </div>
        </Modal>
      )}
    </CustomNode>
  );
}
