import { PencilIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";

export default function InvisibleInput(props) {
  const {
    value,
    onChange,
    name,
    ...passthroughProps
  } = props;

  return (
    <input
      value={value || ""}
      name={name}
      onChange={onChange}
      {...passthroughProps}
    />
  );
}
