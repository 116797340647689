import React, { useContext } from "react";
import { FlowContext } from "~/contexts/flow-context";
import CustomNode from "./CustomNode";
import { useTranslation } from "react-i18next";
import ContactPropertyPickerField from "~/components/fields/ContactPropertyPickerField";
import Input from "../../../elements/Input";
import { ContactsContext } from "../../../../contexts/contacts-context";

export default function UpdatePropertyNode(props) {
  const { t } = useTranslation();

  const { selected, data } = props;
  const { id, options, content } = data;

  const { contact_property } = options;

  const { editAction } = useContext(FlowContext);
  const { contactProperties } = useContext(ContactsContext);

  const custom_contact_property = contactProperties.find(
    (o) => o.id.toString() === contact_property?.split("_")?.[1],
  );

  return (
    <>
      <CustomNode {...props}>
        <div
          className={`w-[280px] py-3 px-3 bg-white border-2 rounded-2xl nodrag ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all space-y-3 ${
            selected ? "ring-opacity-100 z-50" : null
          }`}
        >
          <div className="space-y-1">
            <div className="text-md font-medium">
              {t("automation.scenarios.actions.update_property.title")}
            </div>
            <div className="text-2sm text-darker-gray">
              {t("automation.scenarios.actions.update_property.description")}
            </div>
          </div>
          <div className="space-y-2">
            <ContactPropertyPickerField
              value={contact_property}
              onChange={(value) =>
                editAction(id, {
                  options: { ...options, contact_property: value },
                })
              }
            />
            <Input
              value={content}
              onChange={(value) => editAction(id, { content: value })}
              placeholder={t(
                "automation.scenarios.actions.update_property.placeholder",
              )}
            />
          </div>
        </div>
      </CustomNode>
    </>
  );
}
