import { XMarkIcon } from "@heroicons/react/20/solid";
import { keys } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "~/components/elements/Button";
import Modal from "~/components/shared/Modal";

const variables = {
  contact: [
    "id",
    "username",
    "name",
    "email",
    "phone",
    "follower_count",
    "profile_picture",
  ],
  trigger: ["message", "comment", "date", "ref"],
};

export default function InsertVariable(props) {
  const { onClose, onSelect } = props;

  const { t } = useTranslation();

  const handleSelect = (variable) => {
    onSelect(variable);
    onClose();
  };

  return (
    <Modal onClose={onClose} className="max-w-[520px]">
      <div className="p-4 space-y-4">
        <div className="flex justify-between items-center">
          <div className="font-medium">
            {t("automation.scenarios.actions.message.insert_variable")}
          </div>
          <XMarkIcon className="w-6" onClick={onClose} />
        </div>
        {keys(variables).map((category) => (
          <div className="space-y-2">
            <div className="font-medium text-sm">
              {t(`automation.variables.${category}.title`)}
            </div>
            <div className="flex flex-wrap gap-2">
              {variables[category].map((variable) => (
                <Button
                  label={t(
                    `automation.variables.${category}.fields.${variable}`,
                  )}
                  onClick={() => handleSelect(`{{${category}.${variable}}}`)}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
}
