import React from "react";
import { BaseEdge, getSmoothStepPath } from "@xyflow/react";

export default function NormalEdge(props) {
  const [edgePath] = getSmoothStepPath(props);

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={props.markerEnd}
        className="bg-medium-gray hover:bg-dark-gray"
      />
    </>
  );
}
