import { ArrowPathIcon } from "@heroicons/react/20/solid";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import Button from "~/components/elements/Button";
import PageHeader from "~/components/shared/PageHeader";
import FullScreen from "~/components/utils/FullScreen";
import LabeledInput from "../forms/LabeledInput";
import { UIContext } from "~/contexts/ui-context";
import axios from "axios";
import headers from "~/utils/headers";
import { isEmpty } from "lodash";

export default function PersonalAccountPage(props) {
  const { back } = props;

  const { user, setUser, deleteUser, cancelDeleteUser } =
    useContext(UserContext);
  const { showAlert } = useContext(UIContext);

  const { t } = useTranslation();

  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const handleSave = (evt) => {
    evt.preventDefault();
    if (password.length > 0 && password.length < 6) {
      showAlert({
        title: t("shared.password_too_short"),
        hideCancel: true,
      });
      return;
    }
    if (password.length > 0 && password !== passwordConfirmation) {
      showAlert({
        title: t("shared.passwords_dont_match"),
        hideCancel: true,
      });
      return;
    }
    let payload = {};
    if (name !== user.name) payload.name = name;
    if (email !== user.email) payload.email = email;
    if (password.length > 0) {
      payload.password = password;
      payload.password_confirmation = passwordConfirmation;
    }
    if (isEmpty(payload)) return;
    axios.patch("/api/user", { user: payload }, headers()).then((res) => {
      showAlert({
        title: t("profile.account_updated"),
        hideCancel: true,
      });
      setUser(res.data);
    });
  };

  return (
    <FullScreen>
      <PageHeader
        title={t("profile.personal_account")}
        back={back}
        className="mb-4"
        action={{
          label: t("shared.save"),
          onClick: handleSave,
        }}
      />
      <LabeledInput
        name="name"
        autoComplete="name"
        type="test"
        value={name}
        onChange={setName}
        label={t("inbox.details.name")}
      />
      <LabeledInput
        name="email"
        autoComplete="email"
        type="email"
        value={email}
        onChange={setEmail}
        label={t("inbox.details.email_address")}
      />
      <LabeledInput
        name="password"
        autoComplete="new-password"
        type="password"
        placeholder={t("shared.password_placeholder")}
        value={password}
        onChange={setPassword}
        label={t("shared.password")}
      />
      <LabeledInput
        name="password_confirmation"
        autoComplete="new-password"
        type="password"
        placeholder={t("shared.password_confirmation_placeholder")}
        value={passwordConfirmation}
        onChange={setPasswordConfirmation}
        label={t("shared.password_confirmation")}
      />
      <div className="h-8" />
      {user.delete_at == null ? (
        <a
          className="block p-4 text-2sm underline text-red-500"
          onClick={deleteUser}
        >
          {t("profile.delete_action")}
        </a>
      ) : (
        <a
          className="block p-4 text-2sm underline text-red-500"
          onClick={cancelDeleteUser}
        >
          {t("profile.cancel_delete")}
        </a>
      )}
    </FullScreen>
  );
}
