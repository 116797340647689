import React from "react";
import { useTranslation } from "react-i18next";
import CustomNode from "./CustomNode";

export default function CollectEmailNode(props) {
  const { t } = useTranslation();

  const { selected, data } = props;
  const { id } = data;

  return (
    <CustomNode {...props}>
      <div
        className={`p-3 bg-white border-2 rounded-2xl max-w-xs nodrag ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all ${
          selected ? "ring-opacity-100 z-50" : null
        }`}
      >
        <div className="text-md font-medium mb-1">
          {t("automation.scenarios.actions.collect_email.title")}
        </div>
        <div className="text-2sm text-darker-gray">
          {t("automation.scenarios.actions.collect_email.description")}
        </div>
      </div>
    </CustomNode>
  );
}
