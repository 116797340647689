import nodeTypes from "../nodeTypes";
import AskQuestionResultsNode from "./nodes/AskQuestionResultsNode";
import BranchExpirationResultsNode from "./nodes/BranchExpirationResultsNode";
import CommentResultsNode from "./nodes/CommentResultsNode";
import ConversionLinkResultsNode from "./nodes/ConversionLinkResultsNode";
import DelayResultsNode from "./nodes/DelayResultsNode";
import FolderResultsNode from "./nodes/FolderResultsNode";
import LikeResultsNode from "./nodes/LikeResultsNode";
import MediaMessageResultsNode from "./nodes/MediaMessageResultsNode";
import MessageResultsNode from "./nodes/MessageResultsNode";
import ShareMediaResultsNode from "./nodes/ShareMediaResultsNode";
import TriggerResultsNode from "./nodes/TriggerResultsNode";

export default {
  ...nodeTypes,
  trigger: TriggerResultsNode,
  message: MessageResultsNode,
  media_message: MediaMessageResultsNode,
  share_media: ShareMediaResultsNode,
  comment: CommentResultsNode,
  like: LikeResultsNode,
  delay: DelayResultsNode,
  folder: FolderResultsNode,
  conversion_link: ConversionLinkResultsNode,
  ask_question: AskQuestionResultsNode,
  branch_expiration: BranchExpirationResultsNode,
};
