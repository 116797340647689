import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomNode from "./CustomNode";
import { FlowContext } from "~/contexts/flow-context";
import { delay } from "lodash";
import RepliedIcon from "../../../inbox/RepliedIcon";
import InputCheckbox from "~/components/elements/InputCheckbox";
import { UserContext } from "~/contexts/user-context";

/**
 * Node for replying to a comment with another comment
 * @component
 * @param {Object} props - Properties passed to component
 * @param {Object} props.data - Comment data
 * @param {boolean} props.selected - If true, node is selected
 * @returns
 */

export default function CommentNode(props) {
  const { t } = useTranslation();

  const { organization } = useContext(UserContext);
  const { editAction } = useContext(FlowContext);

  const { data, selected } = props;

  const { id, content, editable = false, options = {} } = data;

  const { autowrite } = options;

  const [localContent, setLocalContent] = useState(content);

  const messageRef = useRef(null);

  const handleChange = (text) => {
    editAction(id, { content: text, editable: false });
    window.getSelection?.()?.empty();
  };

  useEffect(() => {
    if (editable) delay(() => messageRef.current.focus(), 50);
  }, [editable]);

  useEffect(() => setLocalContent(content), [content]);

  return (
    <CustomNode {...props}>
      <div
        className={`relative flex flex-col items-center space-y-3 ${selected || editable ? "z-50" : null}`}
      >
        <div className="flex items-center space-x-2">
          <RepliedIcon className="rotate-180" />
          <div
            className={`relative bg-white border border-neutral-200 rounded-lg text-2sm max-w-xs min-h-[34px] text-left break-words whitespace-pre-wrap ring-offset-1 ring-indigo-400 ring-opacity-0 hover:ring-opacity-100 ${
              selected || editable ? "ring-opacity-100" : null
            } ${editable ? "ring" : "ring-1"} ${
              localContent?.length == 0 && "min-w-[160px]"
            }`}
          >
            {localContent?.length > 0 ? null : (
              <div className="px-2.5 py-2 absolute inset-0 text-gray-400 italic whitespace-nowrap z-0">
                {t("automation.scenarios.actions.comment.placeholder")}
              </div>
            )}
            <div
              ref={messageRef}
              className={`px-2.5 py-2 outline-none select-none nodrag relative z-10 ${
                editable ? "cursor-text" : null
              }`}
              contentEditable={editable}
              onInput={(e) => setLocalContent(e.target.innerText)}
              onBlur={(e) => handleChange(e.target.innerText)}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </div>
        {organization.features.ai && (
          <div className="bg-white border rounded-lg p-2 flex items-center">
            <InputCheckbox
              value={autowrite}
              size="md"
              onClick={() =>
                editAction(id, {
                  options: { ...options, autowrite: !autowrite },
                })
              }
              label={t("automation.scenarios.actions.comment.autowrite")}
            />
          </div>
        )}
      </div>
    </CustomNode>
  );
}
