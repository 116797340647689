import {
  AtSymbolIcon,
  BoltIcon,
  ChatBubbleLeftEllipsisIcon,
  ClockIcon,
  CodeBracketIcon,
  EyeSlashIcon,
  FlagIcon,
  FolderIcon,
  FunnelIcon,
  IdentificationIcon,
  LinkIcon,
  PlusCircleIcon,
} from "@heroicons/react/20/solid";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { compact } from "lodash";
import ShopifyIcon from "~/components/shared/icons/ShopifyIcon";
import Modal from "~/components/shared/Modal";
import { FlowContext } from "~/contexts/flow-context";
import { UserContext } from "~/contexts/user-context";
import classNames from "../../../utils/classNames";
import Button from "../../elements/Button";
import CalendlyIcon from "../../shared/icons/CalendlyIcon";
import AddActionMenuItem from "./AddActionMenuItem";
import triggerTypeOptions from "./triggers/triggerTypeOptions";
import { SubscriptionContext } from "../../../contexts/subscription-context";

export default function AddActionMenu(props) {
  const { t } = useTranslation();

  const { onSubmit, onClose } = props;

  const { organization } = useContext(UserContext);
  const { limits, limitedFeatureAlert } = useContext(SubscriptionContext);
  const { triggers } = useContext(FlowContext);

  const disabledActions =
    triggerTypeOptions[triggers?.[0]?.trigger_type]?.disabled_actions || [];

  const enabledActions =
    triggerTypeOptions[triggers?.[0]?.trigger_type]?.enabled_actions || [];

  const checkDisabled = (actionType) => {
    if (enabledActions.length) {
      return !enabledActions.includes(actionType);
    } else if (disabledActions.length) {
      return disabledActions.includes(actionType);
    } else {
      return false;
    }
  };

  const messages = [
    {
      action_type: "message",
      type: "Actions::Message",
      editable: true,
      content: "",
    },
    {
      action_type: "like",
      type: "Actions::Like",
    },
    {
      action_type: "media_message",
      type: "Actions::MediaMessage",
      editable: true,
      content: "",
      options: {
        media_file_id: null,
      },
    },
    {
      action_type: "share_media",
      type: "Actions::ShareMedia",
      editable: true,
      content: "",
    },
    {
      action_type: "audio_message",
      type: "Actions::AudioMessage",
      editable: true,
      options: {
        media_file_id: null,
      },
    },
  ];

  const conversions = [
    {
      action_type: "ask_question",
      type: "Actions::AskQuestion",
      editable: true,
      default_branch: "option_expired",
      options: {
        expire_duration: 3,
        expire_unit: "days",
        ask_question_options: [],
      },
    },
    {
      action_type: "conversion_link",
      type: "Actions::ConversionLink",
      editable: true,
      default_branch: "option_clicked",
      options: {
        conversion_link_id: null,
        expire_duration: 3,
        expire_unit: "days",
      },
    },
    {
      action_type: "survey",
      type: "Actions::Survey",
      editable: true,
      default_branch: "option_filled",
      options: {
        survey_id: null,
        expire_duration: 3,
        expire_unit: "days",
      },
    },
    organization.calendly_account.connected && {
      action_type: "calendly_link",
      type: "Actions::CalendlyLink",
      icon: CalendlyIcon,
      editable: true,
      plan: organization.calendly_account.plan,
      default_branch: "option_booked",
      options: {
        calendly_event_type_id: null,
        button_label: "Book a meeting",
        expire_duration: 3,
        expire_unit: "days",
      },
    },
    organization.features.ecommerce && {
      action_type: "checkout_link",
      type: "Actions::CheckoutLink",
      icon: ShopifyIcon,
      editable: true,
      options: {
        products: [],
      },
    },
    organization.features.payments && {
      action_type: "payment_link",
      type: "Actions::PaymentLink",
      pro: true,
      icon: LinkIcon,
      editable: true,
      payment_link_id: null,
    },
  ];

  const comments = [
    {
      action_type: "comment",
      type: "Actions::Comment",
      editable: true,
      content: "",
    },
    {
      action_type: "hide_comment",
      type: "Actions::HideComment",
      icon: EyeSlashIcon,
    },
  ];

  const contact = [
    {
      action_type: "folder",
      type: "Actions::Folder",
      icon: FolderIcon,
      menuVisible: true,
      options: {
        folder_action: "add",
        folder_id: null,
      },
    },
    {
      action_type: "update_property",
      type: "Actions::UpdateProperty",
      icon: IdentificationIcon,
      options: {
        contact_property: null,
        content: "",
      },
    },
    {
      action_type: "collect_email",
      type: "Actions::CollectEmail",
      icon: AtSymbolIcon,
      options: {
        wait: true,
      },
    },
  ];

  const logic = [
    {
      action_type: "delay",
      type: "Actions::Delay",
      icon: ClockIcon,
      options: {
        autoFocus: true,
        delay_duration: 60,
        delay_unit: "seconds",
      },
    },
    {
      action_type: "trigger_scenario",
      type: "Actions::TriggerScenario",
      icon: BoltIcon,
      options: {
        scenario_id: null,
      },
    },
    {
      action_type: "wait_for_reply",
      type: "Actions::WaitForReply",
      icon: ChatBubbleLeftEllipsisIcon,
      default_branch: "option_replied",
      options: {
        wait_action: "stop_scenario",
        expire_duration: 3,
        expire_unit: "days",
      },
    },
    {
      action_type: "scenario_condition",
      type: "Actions::ScenarioCondition",
      icon: FunnelIcon,
      default_branch: "option_true",
      options: {
        condition_action: "continue",
        condition_type: "is_follower",
        condition_operator: "is_equal",
      },
    },
    organization.dev && {
      action_type: "content_filter",
      icon: FlagIcon,
      pro: true,
      options: {
        folder_id: null,
      },
    },
    {
      action_type: "http_request",
      type: "Actions::HttpRequest",
      icon: CodeBracketIcon,
      pro: true,
      default_branch: "option_success",
      options: {
        http_method: "GET",
        http_url: "",
        http_headers: [],
        http_params: [],
      },
    },
  ];

  const actionGroups = {
    messages,
    conversions,
    comments,
    contact,
    logic,
  };

  const visibleGroups = [
    "messages",
    "conversions",
    "comments",
    "contact",
    "logic",
  ];

  // Detect active group on scroll
  const [activeGroup, setActiveGroup] = useState("messages");
  const scrollRef = useRef();
  const groupRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.some((entry) => {
          if (entry.isIntersecting) {
            setActiveGroup(entry.target.id);
            return true;
          }
        });
      },
      { threshold: 0.5 },
    );

    groupRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      observer.disconnect();
    };
  });

  const renderGroupListItem = (group, index) => (
    <div
      key={group}
      className={classNames(
        "block px-4 py-2.5 border-r-2 cursor-pointer transition-all hover:bg-blue-100 hover:border-r-active hover:text-blue-500",
        group == activeGroup
          ? "bg-blue-100 border-r-active text-blue-500"
          : "bg-white border-r-transparent",
      )}
      onClick={() => {
        groupRefs.current[index].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }}
    >
      <div className="font-medium text-base leading-5 mb-1">
        {t(`automation.scenarios.action_groups.${group}.title`)}
      </div>
      <div className="text-xs leading-4">
        {t(`automation.scenarios.action_groups.${group}.description`)}
      </div>
    </div>
  );

  const renderGroup = (group, index) => {
    const visibleActions = compact(actionGroups[group]).filter(
      (action) => !checkDisabled(action.action_type),
    );
    if (!visibleActions.length) return null;
    return (
      <div key={group} id={group} ref={(el) => (groupRefs.current[index] = el)}>
        <div className="font-medium mb-1">
          {t(`automation.scenarios.action_groups.${group}.title`)}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 auto-rows-min gap-3">
          {visibleActions.map(renderAction)}
        </div>
      </div>
    );
  };

  const renderAction = (action) => (
    <div>
      <AddActionMenuItem
        key={action.action_type}
        action={action}
        disabled={action.disabled || checkDisabled(action.action_type)}
        onClick={() => handleClickAction(action)}
      />
    </div>
  );

  // this adds the action to the flow when clicked
  // we remove the icon from the payload before submitting
  const handleClickAction = (action) => {
    if (limits.features?.includes(`action_${action.action_type}`)) {
      limitedFeatureAlert();
      return;
    }
    const { icon, ...actionPayload } = action;
    onSubmit(actionPayload);
    onClose();
  };

  return (
    <Modal
      title={t("automation.scenarios.add_step")}
      titleIcon={PlusCircleIcon}
      onClose={onClose}
      className="max-h-[800px]"
      footerLeft={<Button label={t("shared.cancel")} onClick={onClose} />}
    >
      <div className="overflow-hidden flex">
        <div className="border-r overflow-auto w-full max-w-72">
          {visibleGroups.map(renderGroupListItem)}
        </div>
        <div
          className="flex-none flex-shrink-0 overflow-y-auto p-4 space-y-4"
          ref={scrollRef}
        >
          {visibleGroups.map(renderGroup)}
        </div>
      </div>
    </Modal>
  );
}
