import React from "react";
import classNames from "~/utils/classNames";

export default function InlineTooltip(props) {
  const {
    text,
    primary = false,
    align = "left",
    position = "top",
    children,
    className = "",
  } = props;

  return (
    <div className="relative group/tooltip block">
      <div
        className={`transition-opacity opacity-0 group-hover/tooltip:opacity-100 pointer-events-none absolute z-30 ${
          position === "top" ? "bottom-full pb-2.5" : "top-full pt-2.5"
        } ${align === "left" ? "-left-3" : "-right-3"}`}
      >
        <div
          className={classNames(
            primary ? "bg-primary" : "bg-black",
            "text-white px-2 py-2 rounded-lg text-2xs font-medium",
            className,
          )}
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <div
          className={`w-0 h-0 border-y-8 border-x-8 border-transparent ${
            position === "top"
              ? primary
                ? "border-t-primary"
                : "border-t-black" + " -bottom-1.5"
              : primary
                ? "border-b-primary"
                : "border-b-black" + " -top-1.5"
          } absolute ${align === "left" ? "left-3" : "right-3"}`}
        />
      </div>
      {children}
    </div>
  );
}
