import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { ShopifyContext } from "~/contexts/shopify-context";
import { UserContext } from "~/contexts/user-context";

export default function ShopifyProvider(props) {
  const { connected } = props;

  const [loading, setLoading] = useState(true);

  const loadProducts = async (searchQuery = null) => {
    if (!connected) return;
    setLoading(true);
    let url = "/api/shopify/products";
    if (searchQuery?.length) {
      url += `?query=${searchQuery}`;
    }
    const res = await axios.get(url);
    setLoading(false);
    return res.data;
  };

  const shopifyValues = {
    loading,
    loadProducts,
  };

  return (
    <ShopifyContext.Provider value={shopifyValues}>
      {props.children}
    </ShopifyContext.Provider>
  );
}
