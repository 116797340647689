import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ConversionLinkMessage from "../../messages/ConversionLinkMessage";
import { AutomationContext } from "~/contexts/automation-context";
import { truncate } from "lodash";
import Loader from "~/components/utils/Loader";
import Button from "~/components/elements/Button";
import { UIContext } from "~/contexts/ui-context";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import ConversionLinkParam from "./ConversionLinkParam";
import Input from "~/components/elements/Input";
import { createPortal } from "react-dom";
import cleanUrl from "~/utils/cleanUrl";

export default function ConversionLinkDialog(props) {
  const { t } = useTranslation();

  const {
    saveConversionLink,
    saveUploadedImage,
    destroyConversionLink,
    getOpenGraphData,
  } = useContext(AutomationContext);

  const { showPrompt } = useContext(UIContext);

  const {
    conversionLink: initialConversionLink = {
      title: "",
      subtitle: "",
      button_title: "",
      link_provider: "",
      url: "",
      image: "",
      _destroy_image: false,
    },
    onSubmit = () => {},
    onClose,
  } = props;

  const [conversionLink, setConversionLink] = useState(initialConversionLink);
  const [errorMessage, setErrorMessage] = useState(null);

  const [visible, setVisible] = useState(false);

  // animate appear
  useEffect(() => {
    const escapeKey = (evt) => {
      if (evt.key == "Escape") handleClose(evt);
    };
    setTimeout(() => setVisible(true), 50);
    window.addEventListener("keydown", escapeKey);
    return () => window.removeEventListener("keydown", escapeKey);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 150);
  };

  const handleCloseWithConfirm = (evt) => {
    evt?.preventDefault();
    if (dirty) {
      showPrompt(t("conversion_links.close_confirm"), handleClose);
    } else {
      handleClose();
    }
  };

  const fields = [
    {
      name: "title",
      required: true,
      maxLength: 80,
    },
    {
      name: "subtitle",
      maxLength: 80,
    },
    {
      name: "button_title",
      required: true,
      maxLength: 20,
    },
  ];

  // fetch open graph data to prefill title & subtitle
  const [loadingData, setLoadingData] = useState(false);

  const fetchUrlData = (url) => {
    try {
      // Fetch open graph data from url if no title
      if (conversionLink.title) return;
      setLoadingData(true);

      const cleanedUrl = cleanUrl(url);

      getOpenGraphData(cleanedUrl).then((data) => {
        setLoadingData(false);
        if (!data.body) return;

        setConversionLink((conversionLink) => ({
          ...conversionLink,
          title:
            conversionLink?.title ||
            truncate(data?.title, { length: 80 }) ||
            "",
          subtitle:
            conversionLink?.subtitle ||
            truncate(data?.description, { length: 80 }) ||
            "",
          image_url: conversionLink?.image_url || data?.images[0] || "",
        }));
      });
    } catch (e) {
      setLoadingData(false);
    }
  };

  const [dirty, setDirty] = useState(false);

  const handleFieldChange = (field, value) => {
    setConversionLink({ ...conversionLink, [field]: value });
    setErrorMessage(null);
    if (field == "url") {
      extractUrlParams(value);
      fetchUrlData(value);
    }
  };

  useEffect(() => {
    if (conversionLink !== initialConversionLink) setDirty(true);
  }, [conversionLink]);

  // URL Params

  const [params, setParams] = useState(
    initialConversionLink?.conversion_link_params || [],
  );

  const extractUrlParams = (changedUrl) => {
    if (!changedUrl) return;

    try {
      const cleanedUrl = cleanUrl(changedUrl);

      // Extract search params from url
      const url = new URL(cleanedUrl);

      url.searchParams.forEach((value, key) => {
        // Don't overwrite existing params
        if (
          !conversionLink.conversion_link_params_attributes?.some(
            (link_param) => link_param.key === key,
          )
        ) {
          addParam(key, value);
        }
      });

      const urlWithoutParams = changedUrl.replace(url.search, "");

      setConversionLink({ ...conversionLink, url: urlWithoutParams });
    } catch (e) {
      console.log(e);
      setErrorMessage(t("automation.conversion_links.url_invalid"));
    }
  };

  const addUTMParams = () => {
    if (utmPresent) return;
    setParams((params) => [
      ...params,
      {
        key: "utm_source",
        value: "instagram",
      },
      {
        key: "utm_medium",
        value: "direct_message",
      },
      {
        key: "utm_campaign",
        value: "inro_link",
      },
    ]);
  };

  const addParam = (key, value) => {
    setParams((params) => [...params, { key, value }]);
  };

  const addBlankParam = () => {
    setParams((params) => [...params, { key: "", value: "" }]);
  };

  const editParam = (index, param) => {
    setParams((params) => [
      ...params.slice(0, index),
      param,
      ...params.slice(index + 1),
    ]);
  };

  const removeParam = (index) => {
    if (params[index].id) {
      setParams((params) =>
        params.map((p, i) => (i == index ? { ...p, _destroy: true } : p)),
      );
    } else {
      setParams((params) => [
        ...params.slice(0, index),
        ...params.slice(index + 1),
      ]);
    }
  };

  const utmPresent = useMemo(
    () => params.some((p) => p.key.includes("utm_")),
    [params],
  );

  const validParams = useMemo(
    () => params.filter((p) => p.key && !p._destroy),
    [params],
  );

  const fullUrl = useMemo(() => {
    if (!conversionLink.url) return "";
    const params =
      validParams.length > 0
        ? "?" + validParams.map((p) => `${p.key}=${p.value}`).join("&")
        : "";

    return conversionLink.url + params;
  }, [conversionLink.url, validParams]);

  // Validation & saving

  const isValid =
    fields.filter((f) => f.required).every((f) => !!conversionLink[f.name]) &&
    !errorMessage;

  const [loadingSave, setLoadingSave] = useState(false);

  const handleSave = async () => {
    if (!isValid) return;

    setLoadingSave(true);

    const nestedParams = params.filter((p) => p._destroy || (p.key && p.value));

    const cleanedUrl = cleanUrl(conversionLink.url.trim());

    // Save conversion link without uploaded image
    const savedLink = await saveConversionLink({
      id: conversionLink.id ? parseInt(conversionLink.id) : null,
      title: conversionLink.title.trim(),
      subtitle: conversionLink.subtitle.trim(),
      button_title: conversionLink.button_title.trim(),
      url: cleanedUrl,
      image_url: conversionLink.image ? null : conversionLink.image_url,
      _destroy_image: conversionLink._destroy_image,
      conversion_link_params_attributes: nestedParams,
    });

    // Upload image if present
    if (conversionLink.image) {
      const data = new FormData(formRef.current);
      saveUploadedImage(data, savedLink.id);
    }
    setLoadingSave(false);
    onSubmit(savedLink);
    handleClose();
  };

  const handleDelete = () => {
    showPrompt(t("conversion_links.delete_confirm"), () => {
      destroyConversionLink(conversionLink.id);
      handleClose();
    });
  };

  const handleImageChange = (evt) => {
    // Display image preview
    const reader = new FileReader();
    reader.readAsDataURL(evt.target.files[0]);
    reader.onloadend = () => {
      setConversionLink({
        ...conversionLink,
        image: reader.result,
        _destroy_image: false,
      });
    };
  };

  const onImageRemove = () => {
    setConversionLink({
      ...conversionLink,
      image: null,
      image_url: null,
      _destroy_image: true,
    });
  };

  const formRef = useRef(null);

  return createPortal(
    <div
      className={`absolute inset-0 flex flex-col items-stretch sm:items-center justify-center p-4 select-none transition-all ${
        visible ? "pointer-events-auto" : "pointer-events-none"
      }`}
      style={{
        zIndex: 900,
      }}
    >
      <div
        className={`absolute inset-0 bg-black/20 transition-opacity ${
          visible ? "opacity-100" : "opacity-0"
        }`}
        onClick={handleCloseWithConfirm}
      />
      <div
        className={`sm:w-auto bg-white shadow-lg rounded-xl overflow-auto relative transition-opacity ${
          visible ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className="w-full">
          <div className="flex flex-col sm:flex-row items-stretch">
            <div className="sm:w-96 flex-grow py-8 px-4 space-y-3 border-r">
              <div className="space-y-2">
                <Input
                  name="url"
                  placeholder={t(`conversion_links.fields.url`)}
                  value={conversionLink.url}
                  onChange={(value) => handleFieldChange("url", value)}
                  debounce={500}
                  autoFocus
                />
                {params.map((param, index) =>
                  param._destroy ? null : (
                    <ConversionLinkParam
                      key={index}
                      param={param}
                      onEdit={(p) => editParam(index, p)}
                      onRemove={() => removeParam(index)}
                    />
                  ),
                )}
                <div className="flex flex-col sm:flex-row gap-2">
                  <Button
                    label={t("conversion_links.params.add")}
                    onClick={addBlankParam}
                    icon={PlusCircleIcon}
                  />
                  {!utmPresent && (
                    <Button
                      label={t("conversion_links.params.add_utm")}
                      onClick={addUTMParams}
                      icon={PlusCircleIcon}
                    />
                  )}
                </div>
              </div>
              <div className="w-full h-1" />
              {fields.map((field) => (
                <Input
                  key={field.name}
                  placeholder={t(`conversion_links.fields.${field.name}`)}
                  value={conversionLink[field.name]}
                  onChange={(value) => handleFieldChange(field.name, value)}
                  clearButton
                  {...field}
                />
              ))}
              <form ref={formRef}>
                <label>
                  <Button
                    readOnly
                    className="w-full"
                    icon={PlusCircleIcon}
                    label={t(
                      `conversion_links.fields.${conversionLink.image ? "change_image" : "add_image"}`,
                    )}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    name="image"
                    onChange={handleImageChange}
                  />
                </label>
              </form>
            </div>
            <div className="w-full sm:w-80 py-4 sm:py-8 bg-lighter-gray hidden sm:flex flex-col items-center justify-center">
              {loadingData ? (
                <Loader stroke="#8D8D8D" width={34} strokeWidth={8} />
              ) : (
                <div className="space-y-4">
                  <ConversionLinkMessage
                    message={{ conversion_link: conversionLink }}
                    onImageRemove={onImageRemove}
                    editable
                    preview
                  />
                  {(fullUrl.length > 1 || conversionLink.url > 1) && (
                    <div className="opacity-75 ml-4 w-64 border bg-white rounded px-1.5 py-1 text-2xs font-mono break-all">
                      {fullUrl || conversionLink.url}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="border-t p-4 flex items-center justify-between">
            <div className="flex space-x-2">
              <Button
                label={t("shared.cancel")}
                onClick={handleCloseWithConfirm}
              />
              {conversionLink?.id ? (
                <Button
                  label={t("shared.delete")}
                  onClick={handleDelete}
                  style="danger"
                />
              ) : null}
            </div>
            <div className="flex items-center space-x-2">
              {errorMessage && (
                <div className="text-red text-sm text-medium">
                  {errorMessage}
                </div>
              )}
              <Button
                label={t("shared.save")}
                style="primary"
                onClick={handleSave}
                disabled={!isValid}
                loading={loadingSave}
              />
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body,
  );
}
