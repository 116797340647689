import React, { useCallback, useContext, useMemo } from "react";
import { ContentContext } from "~/contexts/content-context";
import MediaItem from "~/components/content/medias/MediaItem";

export default function ShareMediaAction(props) {
  const { action } = props;

  const { medias } = useContext(ContentContext);

  const media = useMemo(
    () => medias?.find((m) => m.instagram_id === action.options?.media_ig_id),
    [action.options?.media_ig_id, medias],
  );

  const openMedia = useCallback(() => {
    window.open(media.permalink, "_blank");
  }, [media]);

  if (!media) return null;

  return <MediaItem media={media} onClick={openMedia} />;
}
