import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlowContext } from "~/contexts/flow-context";
import {
  ListBulletIcon,
  PencilIcon,
  PencilSquareIcon,
} from "@heroicons/react/20/solid";
import CustomNode from "./CustomNode";
import { AutomationContext } from "~/contexts/automation-context";
import Modal from "~/components/shared/Modal";
import SurveyForm from "~/components/surveys/SurveyForm";
import { UIContext } from "~/contexts/ui-context";
import TemplateMessage from "~/components/messages/TemplateMessage";

export default function SurveyNode(props) {
  const { t } = useTranslation();

  const { surveys } = useContext(AutomationContext);
  const { editAction } = useContext(FlowContext);
  const { showMenu } = useContext(UIContext);

  const { selected, data } = props;
  const { id, options, editable = false, survey } = data;

  const { survey_id } = options;

  const [surveyDialogVisible, setSurveyFormVisible] = useState(false);

  const handleShowMenu = () => {
    showMenu({
      title: t("automation.scenarios.actions.survey.placeholder"),
      actions: [
        ...surveys.map((survey) => {
          return {
            label: survey.title,
            bold: true,
            action: () => handlePick(survey),
          };
        }),
        {
          label: t("surveys.create"),
          action: () => setSurveyFormVisible(true),
        },
      ],
    });
  };

  const handlePick = (survey) => {
    editAction(id, { survey, options: { survey_id: survey.id } });
  };

  const buttons =
    survey?.survey_type == "single_choice"
      ? survey.survey_options
      : [
          {
            title: survey?.button_label,
          },
        ];

  useEffect(() => {
    if (editable) handleShowMenu();
  }, [editable]);

  return (
    <CustomNode
      {...props}
      buttons={[
        {
          icon: PencilSquareIcon,
          onClick: () => setSurveyFormVisible(true),
        },
      ]}
      icon={ListBulletIcon}
    >
      <div className={`relative ${selected || editable ? "z-50" : null}`}>
        {survey ? (
          <TemplateMessage
            className={`ring-1 ring-offset-1 ring-indigo-400 ring-opacity-0 hover:ring-opacity-100 ${
              selected ? "ring-opacity-100" : null
            }`}
            template={{
              title: survey.title,
              subtitle: survey.subtitle,
              buttons: buttons,
            }}
          />
        ) : (
          <div
            className="py-3 px-4 bg-white border-2 border-medium-gray text-dark-gray border-dashed rounded-2xl cursor-pointer nodrag opacity-75 hover:opacity-100 transition-opacity flex space-x-2 items-center"
            onClick={handleShowMenu}
          >
            <ListBulletIcon className="w-4" />
            <div className="text-md font-medium text-center">
              {t("automation.scenarios.actions.survey.placeholder")}
            </div>
          </div>
        )}
      </div>
      {surveyDialogVisible && (
        <Modal onClose={() => setSurveyFormVisible(false)}>
          <SurveyForm onSave={handlePick} survey={survey || {}} />
        </Modal>
      )}
    </CustomNode>
  );
}
