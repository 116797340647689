import { isNumber } from "lodash";

export default function validateAction(action) {
  if (action?._destroy) return true;

  switch (action?.action_type) {
    case "message":
      return !!action.content?.length;
    case "delay":
      return isNumber(action.options?.delay_duration);
    case "share_media":
      return !!action.options?.media_ig_id;
    case "survey":
      return action.options?.survey_id;
    case "conversion_link":
      return action.options?.conversion_link_id;
    case "ask_question":
      return (
        !!action.content?.length &&
        action.options?.ask_question_options?.length > 0 &&
        action.options?.ask_question_options?.every((option) => !!option.title)
      );
    case "checkout_link":
      return action.options?.products?.length > 0;
    case "payment_link":
      return !!action.options?.payment_amount;
    case "http_request":
      return !!action.options?.http_url && !!action.options?.http_method;
    default:
      return true;
  }
}
