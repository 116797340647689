import React, { useEffect } from "react";

export default function TableRow(props) {
  const { item, columns, selected, onClick, onRender = () => {} } = props;

  useEffect(() => !item.loaded && onRender(item), [item.id, item.loaded]);

  return (
    <tr
      key={item.id}
      onClick={() => onClick(item)}
      className={`group/row border-b border-b-default divide-x divide-default ${selected ? "bg-neutral-100" : "hover:bg-neutral-50"}`}
    >
      {columns.map((column, index) => (
        <td
          key={index}
          className={`group/cell overflow-hidden py-3 px-2 text-sm text-gray-500
           ${column.className || ""} ${column.cellClassName || ""}`}
          onClick={(evt) => column?.onClick(evt, item)}
        >
          {column.accessor && column.accessor(item)}
        </td>
      ))}
    </tr>
  );
}
