import React, { useContext, useEffect, useState } from "react";
import shopifyIcon from "../../../../assets/images/shopify_icon.png";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import ContactHeader from "../../contacts/ContactHeader";
import Button from "~/components/elements/Button";
import axios from "axios";
import { isEmpty } from "lodash";
import InputField from "~/components/shared/InputField";
import ExternalAccount from "./ExternalAccount";

export default function ShopifyAccount() {
  const { organization } = useContext(UserContext);

  const { t } = useTranslation();

  const [shopifyDomain, setShopifyDomain] = useState(
    organization.shopify_domain || "",
  );

  const handleConnectShopify = () => {
    if (isEmpty(shopifyDomain)) return;

    window.location.replace(
      `/users/auth/shopify?shopify_domain=${shopifyDomain}`,
    );
  };

  const [shopifyInfo, setShopifyInfo] = useState(null);

  const getShopifyInfo = () => {
    axios.get("/api/shopify/shop").then((res) => setShopifyInfo(res.data));
  };

  useEffect(() => {
    if (organization.shopify_connected) getShopifyInfo();
  }, [organization.shopify_connected]);

  return (
    <ExternalAccount
      icon={shopifyIcon}
      provider="shopify"
      connected={organization.shopify_connected}
      name={shopifyInfo?.name}
      subname={shopifyInfo?.primaryDomain?.host}
      externalUrl={shopifyInfo?.url}
      connect={
        <>
          <InputField
            value={shopifyDomain}
            onChange={setShopifyDomain}
            rightGroup=".myshopify.com"
          />
          <Button
            label={t("profile.external_accounts.shopify.connect")}
            className="w-full"
            disabled={isEmpty(shopifyDomain)}
            onClick={handleConnectShopify}
          />
        </>
      }
    />
  );
}
