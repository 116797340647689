import React from "react";
import { useReactFlow, useNodeId } from "@xyflow/react";
import { QuestionMarkCircleIcon, TrashIcon } from "@heroicons/react/20/solid";
import Button from "~/components/elements/Button";
import classNames from "~/utils/classNames";

export default function NodeButtons(props) {
  const { selected, buttons = [], help = false, className = "" } = props;

  const id = useNodeId();
  const { deleteElements } = useReactFlow();

  return (
    <div
      className={classNames(
        "flex space-x-2 absolute top-1/2 -translate-y-1/2 left-full pl-3 py-4 !mt-0 transition-opacity opacity-0 group-hover:opacity-100",
        selected && "opacity-100",
        className,
      )}
    >
      {buttons?.map((button, index) => (
        <Button {...button} key={index} size="small" />
      ))}
      {help && (
        <Button icon={QuestionMarkCircleIcon} href={help} size="small" />
      )}
      <Button
        icon={TrashIcon}
        onClick={() => deleteElements({ nodes: [{ id }] })}
        size="small"
      />
    </div>
  );
}
