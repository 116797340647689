import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MediaPickerField from "~/components/fields/MediaPickerField";
import Button from "~/components/elements/Button";
import Input from "~/components/elements/Input";
import StoryPickerField from "~/components/fields/StoryPickerField";
import { AutomationContext } from "~/contexts/automation-context";
import { CampaignsContext } from "~/contexts/campaigns-context";
import { ContactsContext } from "~/contexts/contacts-context";
import FolderFilter from "./FolderFilter";
import ContactPropertyPickerField from "../../fields/ContactPropertyPickerField";
import InputSelect from "../../elements/InputSelect";
import classNames from "../../../utils/classNames";

const dateRanges = [
  "today",
  "yesterday",
  "last_7_days",
  "last_30_days",
  "last_90_days",
  "last_365_days",
  "all_time",
  "custom",
];

const equalityOptions = ["is_equal", "is_not_equal"];

const presenceOptions = ["is_present", "is_not_present"];

const inclusionOptions = ["contains", "does_not_contain"];

const comparisonOptions = ["is_equal", "is_above", "is_below"];

export default function ContactFilter(props) {
  const { t } = useTranslation();

  const { filter, onChange, onRemove, disabled } = props;

  const {
    filter_type,
    date_range,
    operator,
    value,
    campaign_id,
    story_id,
    media_id,
    scenario_id,
    options = {},
  } = filter;

  const { languages, loadLanguages } = useContext(ContactsContext);
  const { scenarios, loadScenarios } = useContext(AutomationContext);
  const { campaigns, loadCampaigns } = useContext(CampaignsContext);

  const handleChange = (key) => (value) => {
    onChange({ ...filter, [key]: value });
  };

  const handleChangeOption = (key) => (value) => {
    onChange({ ...filter, options: { ...options, [key]: value } });
  };

  // This function generates another function that returns a select element
  const operatorSelect = (options) => () => {
    return (
      <InputSelect
        disabled={disabled}
        onChange={handleChange("operator")}
        value={operator || "is_present"}
        options={options.map((option) => ({
          value: option,
          label: t(`contacts.filters.options.operators.${option}`),
        }))}
      />
    );
  };

  // TODO merge this with filterTypes.js
  const filter_categories = {
    any_engagement: {
      options: [],
      hasDateField: true,
    },
    message: {
      options: [],
      hasDateField: true,
    },
    referral_ad: {
      options: [],
      hasDateField: true,
    },
    media_mention: {
      options: [],
      hasDateField: true,
    },
    clicked_conversion_link: {
      options: [],
      hasDateField: true,
    },
    appointment: {
      options: [],
      hasDateField: true,
    },
    payment: {
      options: [
        () => (
          <InputSelect
            value={options["payment_status"] || "any"}
            onChange={handleChangeOption("payment_status")}
            disabled={disabled}
            options={[
              "any",
              "awaiting",
              "paid",
              "cancelled",
              "manual",
              "attempted",
              "failed",
              "refunded",
            ].map((option, index) => ({
              value: option,
              label: t("payments.labels." + option),
            }))}
          />
        ),
      ],
      hasDateField: true,
      hasValueField: true,
    },
    is_follower: {
      options: [
        () => (
          <select
            className="input-select"
            disabled={disabled}
            onChange={(evt) =>
              onChange({ ...filter, operator: evt.target.value })
            }
            value={operator || "is_true"}
          >
            <option value="is_true">
              {t("contacts.filters.options.is_follower.is_true")}
            </option>
            <option value="is_false">
              {t("contacts.filters.options.is_follower.is_false")}
            </option>
          </select>
        ),
      ],
    },
    follower_count: {
      options: [operatorSelect(comparisonOptions)],
      hasValueField: true,
    },
    folder: {
      component: FolderFilter,
    },
    campaign: {
      options: [
        () => (
          <select
            className="input-select"
            disabled={disabled}
            onChange={(evt) =>
              onChange({ ...filter, campaign_id: evt.target.value })
            }
            value={campaign_id || ""}
          >
            <option>{t("contacts.filters.options.campaigns.any")}</option>
            {campaigns.map((campaign, index) => (
              <option value={campaign.id} key={index}>
                {campaign.title}
              </option>
            ))}
          </select>
        ),
      ],
      hasDateField: true,
    },
    email: {
      options: [operatorSelect([...presenceOptions, ...inclusionOptions])],
      hasValueField: true,
    },
    name: {
      options: [operatorSelect([...presenceOptions, ...inclusionOptions])],
      hasValueField: true,
    },
    username: {
      options: [operatorSelect([...presenceOptions, ...inclusionOptions])],
      hasValueField: true,
    },
    phone: {
      options: [operatorSelect([...presenceOptions, ...inclusionOptions])],
      hasValueField: true,
    },
    language: {
      options: [
        () => (
          <InputSelect
            disabled={disabled}
            onChange={handleChange("value")}
            value={value || "en"}
            options={languages.map((language) => ({
              value: language,
              label: t("languages." + language),
            }))}
          />
        ),
      ],
    },
    contact_property: {
      options: [
        () => (
          <ContactPropertyPickerField
            value={options["contact_property"] || ""}
            onChange={handleChangeOption("contact_property")}
          />
        ),
        operatorSelect([
          ...equalityOptions,
          ...presenceOptions,
          ...inclusionOptions,
        ]),
      ],
      hasValueField: true,
    },
    story_reply: {
      options: [
        () => (
          <StoryPickerField
            value={story_id || ""}
            onChange={handleChange("story_id")}
          />
        ),
      ],
      hasDateField: true,
    },
    story_mention: {
      options: [],
      hasDateField: true,
    },
    comment: {
      options: [
        () => (
          <MediaPickerField
            value={media_id || ""}
            onChange={handleChange("media_id")}
          />
        ),
      ],
      hasDateField: true,
    },
    scenario: {
      options: [
        () => (
          <select
            className="input-select"
            disabled={disabled}
            onChange={(evt) =>
              onChange({ ...filter, scenario_id: evt.target.value })
            }
            value={scenario_id || ""}
          >
            <option>{t("contacts.filters.options.scenarios.any")}</option>
            {scenarios.map((scenario, index) => (
              <option value={scenario.id} key={index}>
                {scenario.title}
              </option>
            ))}
          </select>
        ),
      ],
      hasDateField: true,
    },
  };

  const filterCategory = filter_categories[filter_type] || {};

  // load data for specific filters
  useEffect(() => {
    if (filter_type === "language") loadLanguages();
    if (filter_type === "scenario") loadScenarios();
    if (filter_type === "campaign") loadCampaigns();
  }, [filter_type]);

  if (filterCategory.component) {
    const Component = filterCategory.component;
    return (
      <Component
        filter={filter}
        onChange={onChange}
        onRemove={onRemove}
        disabled={disabled}
      />
    );
  }

  return (
    <div
      className={classNames(
        "flex items-center space-x-2",
        disabled && "pointer-events-none",
      )}
    >
      <span className="font-semibold text-md">
        {t(`contacts.filters.categories.${filter_type}.description`)}
      </span>

      {filterCategory.hasDateField && (
        <select
          className="input-select"
          disabled={disabled}
          onChange={(evt) =>
            onChange({ ...filter, date_range: evt.target.value })
          }
          value={date_range}
        >
          {dateRanges.map((range, index) => (
            <option value={range} key={index}>
              {t(`contacts.filters.options.date_ranges.${range}`)}
            </option>
          ))}
        </select>
      )}

      {/* Filter options */}
      {filterCategory.options?.map((filterOption, index) => (
        <div key={index}>{filterOption()}</div>
      ))}
      {filterCategory.hasValueField &&
        [
          "is_equal",
          "is_not_equal",
          "is_above",
          "is_below",
          "contains",
          "does_not_contain",
        ].includes(operator) && (
          <Input
            disabled={disabled}
            onChange={(value) => onChange({ ...filter, value })}
            value={value}
            debounce={500}
          />
        )}
      <Button
        label={t("contacts.filters.remove_filter")}
        disabled={disabled}
        onClick={onRemove}
      />
    </div>
  );
}
